import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import useUser from "../../../hooks/useUser";
import { CaseHandlerTypes, MemberReportConfigType } from "../interfaces";
import { getMemberReportConfig, upsertMemberReportConfig } from "../services";
import { capitalize, isEmpty } from "lodash";
import toast from "react-hot-toast";
import styles from "./index.module.scss";
import MemberReportConfigTabs from "./Tabs/MemberReportConfigTabs";
import { MemberReportTabsEnum } from "../services/constant";
import CaseConfigForm from "./CaseConfig/CaseConfig";
import ProductHiearchyForm from "./ProductHiearchyConfigForm/ProductHierarchyForm";
import CustomerAnalysisPeriodForm from "./CustomerAnalysisPeriodForm/CustomerAnalysisPeriodForm";

export default function MemberReportConfig() {
  const user = useUser();
  const { selectedBusiness } = user;
  const [memberReportConfig, setMemberReportConfig] =
    useState<MemberReportConfigType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<MemberReportTabsEnum>(
    MemberReportTabsEnum.Recency
  );
  useEffect(() => {
    setIsLoading(true);
    setMemberReportConfig(null);
    getMemberReportConfig(selectedBusiness.id)
      .then((data) => {
        if (isEmpty(data)) {
          setMemberReportConfig(null);
        } else {
          setMemberReportConfig(data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setIsLoading(false);
        console.log(err);
      });
  }, [tab, selectedBusiness]);

  const handleMemberReportConfigSave = async (
    configData: MemberReportConfigType
  ) => {
    let newMemberReportConfig: MemberReportConfigType = {
      ...memberReportConfig,
      ...configData,
    };

    return upsertMemberReportConfig(selectedBusiness.id, newMemberReportConfig)
      .then(() => {
        toast.success("Configurations saved");
        setMemberReportConfig(newMemberReportConfig);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };
  const businessSuffix = ` for ${capitalize(selectedBusiness.name)}`;
  return (
    <Box id={styles.memberReportConfigContainer}>
      <Box className={styles.memberReportConfigTabs}>
        <MemberReportConfigTabs tab={tab} setTab={setTab} />
      </Box>
      <Box>
        {tab === MemberReportTabsEnum.Recency && (
          <Box>
            <CaseConfigForm
              title={`Upto Recency Settings ${businessSuffix}`}
              description={
                "This will be used in member  report and in various areas"
              }
              configLabel="Days"
              configName={CaseHandlerTypes.uptoRecency}
              configData={memberReportConfig}
              handleConfigSave={handleMemberReportConfigSave}
              isLoading={isLoading}
            />
          </Box>
        )}
        {tab === MemberReportTabsEnum.Frequency && (
          <Box>
            <CaseConfigForm
              title={`Within Frequency Settings ${businessSuffix}`}
              description={
                "This will be used in member report and in various areas"
              }
              configLabel="visits"
              configName={CaseHandlerTypes.withinFrequency}
              configData={memberReportConfig}
              handleConfigSave={handleMemberReportConfigSave}
              isLoading={isLoading}
            />
            <CaseConfigForm
              title={`Upto Frequency Settings ${businessSuffix}`}
              description={
                "This will be used in member  report and in various areas"
              }
              configLabel="visits"
              configName={CaseHandlerTypes.uptoFrequency}
              configData={memberReportConfig}
              handleConfigSave={handleMemberReportConfigSave}
              isLoading={isLoading}
            />
          </Box>
        )}
        {tab === MemberReportTabsEnum.Monetary && (
          <>
            <Box>
              <CaseConfigForm
                title={`Within Total Spend Settings ${businessSuffix}`}
                description={
                  "This will be used in member report and in various areas"
                }
                configName={CaseHandlerTypes.withinTotalSpend}
                configLabel={"Amount"}
                configData={memberReportConfig}
                handleConfigSave={handleMemberReportConfigSave}
                isLoading={isLoading}
              />
            </Box>
            <Box>
              <CaseConfigForm
                title={`Upto Total Spend Settings ${businessSuffix}`}
                description={
                  "This will be used in member report and in various areas"
                }
                configName={CaseHandlerTypes.uptoTotalSpend}
                configLabel={"Amount"}
                configData={memberReportConfig}
                handleConfigSave={handleMemberReportConfigSave}
                isLoading={isLoading}
              />
            </Box>

            <Box>
              <CaseConfigForm
                title={`Upto Average MRP purchase Settings ${businessSuffix}`}
                description={
                  "This will be used in member  report and in various areas"
                }
                configLabel="Amount"
                configName={CaseHandlerTypes.uptoMRP}
                configData={memberReportConfig}
                handleConfigSave={handleMemberReportConfigSave}
                isLoading={isLoading}
              />
            </Box>
          </>
        )}

        {tab === MemberReportTabsEnum.Hierarchy && (
          <Box>
            <ProductHiearchyForm
              title={`Product Hierarchy Settings ${businessSuffix}`}
              description={
                "The member report highlights the best-performing products of each selected product hierarchy."
              }
              data={memberReportConfig}
              setData={handleMemberReportConfigSave}
              isLoading={isLoading}
            ></ProductHiearchyForm>
          </Box>
        )}
        {tab === MemberReportTabsEnum.AnalysisPeriod && (
          <Box>
            <CustomerAnalysisPeriodForm
              title={`Customer Purchase Value and Visit Analysis Period Settings ${businessSuffix}`}
              description={
                "This will be used in member  report and in various areas"
              }
              data={memberReportConfig}
              setData={handleMemberReportConfigSave}
              isLoading={isLoading}
            ></CustomerAnalysisPeriodForm>
          </Box>
        )}
      </Box>
    </Box>
  );
}
