import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import { map } from "lodash";
import React from "react";
import { Description } from "@mui/icons-material";
import config from "../../../../config";

import style from "./MemberReportConfigTabs.module.scss";

import {
  memberReportConfigTabs,
  MemberReportTabsEnum,
} from "../../services/constant";

interface MemberReportConfigProps {
  tab: MemberReportTabsEnum;
  setTab: (tab: MemberReportTabsEnum) => void;
}

export default function MemberReportConfigTabs({
  tab,
  setTab,
}: MemberReportConfigProps) {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue as MemberReportTabsEnum);
  };

  return (
    <Box id={style.MemberReportConfigFilter}>
      <Grid container direction="column">
        <Grid item>
          <Tabs
            orientation="vertical"
            value={tab}
            onChange={handleChange}
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            {map(memberReportConfigTabs, (lct) => (
              <Tab
                sx={{ display: "flex", alignItems: "flex-start" }}
                label={lct.label}
                value={lct.value}
                className="dropdown"
                key={lct.value}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item paddingTop={2} paddingRight={2} paddingBlockEnd={2}>
          <Button
            component="a"
            href={`${config.dashboard}/reports/enrollment`}
            target="_blank"
            startIcon={<Description />}
            color="primary"
          >
            Generate
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
