import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const CommerceConnectSettings = lazy(() => import("../components"));

const ProductSyncConfig = lazy(
  () => import("../components/Tabs/ProductSyncConfig/index")
);

const ProductHierarchyTree = lazy(
  () => import("../../../ProductHierarchyTree/components/ProductHierarchyTree")
);

const UploadImages = lazy(
  () => import("../../../UploadImages/components/UploadImages")
);

const CommerceConnectSettingsRoute: RouteObject = {
  path: "settings/modules/commerce-connect",
  element: <CommerceConnectSettings />,
  children: [
    {
      index: true,
      element: <ProductSyncConfig />,
    },
    {
      path: "product-hierarchy-tree",
      element: <ProductHierarchyTree />,
    },
    {
      path: "upload-images",
      element: <UploadImages />,
    },
  ],
};

export default CommerceConnectSettingsRoute;
