import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const UploadProductsComponent = lazy(
  () => import("../components/UploadProducts")
);

const UploadProducts: RouteObject = {
  path: "/commerce-connect/upload-products",
  element: <UploadProductsComponent />,
};

export default UploadProducts;
