import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const TaskDetailComponent = lazy(() => import("../components/TaskDetail"));

const TaskDetail: RouteObject = {
  path: "/commerce-connect/task/:id",
  element: <TaskDetailComponent />,
};

export default TaskDetail;
