export enum CaseTypes {
  lesserThan = "lesserThan",
  greaterThan = "greaterThan",
  betweenAnd = "betweenAnd",
}
export interface caseConfig {
  value: number[];
  type: CaseTypes;
  tag: string;
}
export interface MemberReportConfigType {
  withinFrequency?: caseConfig[];
  uptoFrequency?: caseConfig[];
  uptoRecency?: caseConfig[];
  uptoMRP?: caseConfig[];
  productHierarchies?: string[];
  withinTotalSpend?: caseConfig[];
  uptoTotalSpend?: caseConfig[];
  analysisPeriod?: number;
}
export enum CaseHandlerTypes {
  withinFrequency = "withinFrequency",
  uptoFrequency = "uptoFrequency",
  uptoRecency = "uptoRecency",
  uptoMRP = "uptoMRP",
  withinTotalSpend = "withinTotalSpend",
  uptoTotalSpend = "uptoTotalSpend",
}
