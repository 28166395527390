import { Controller, useForm } from "react-hook-form";

import { Box, Card, Paper } from "@mui/material";
import styles from "./ProductHierarchyForm.module.scss";
import Header from "../../../../shared/components/Header/Header";
import LoadingButton from "../../../../shared/components/LoadingButton/LoadingButton";
import ProductHierarchyConfig from "../ProductHierarchyConfig/ProductHierarchyConfig";
import { MemberReportConfigType } from "../../interfaces";
import CaseConfigLoader from "../CaseConfig/CaseConfigLoader";

interface ProductHiearchyFormProps {
  title: string;
  description: string;
  data: MemberReportConfigType | null;
  setData: (data: MemberReportConfigType) => Promise<void>;
  isLoading: boolean;
}

export default function ProductHiearchyForm({
  title,
  description,
  data,
  setData,
  isLoading,
}: ProductHiearchyFormProps) {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues:
      data && data.productHierarchies && data.productHierarchies.length > 0
        ? data
        : { productHierarchies: [] },
  });
  const onSubmit = async (data: MemberReportConfigType) => {
    await setData(data).then(() => reset(data));
  };

  return (
    <Paper
      component="form"
      elevation={0}
      id={styles.MemberConfig}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box className={styles.header}>
        <Header title={title} variant={"h6"} description={description}></Header>
        <Box className={styles.saveButtonContainer}>
          {!isLoading && (
            <LoadingButton
              // sx={{ mt: 2 }}
              loading={isSubmitting}
              disabled={!isDirty}
              variant="contained"
              type="submit"
            >
              Save configuration
            </LoadingButton>
          )}
        </Box>
      </Box>
      {!isLoading ? (
        <Card className={styles.memberCard}>
          <Box className={styles.memberColumns}>
            <Controller
              name="productHierarchies"
              control={control}
              rules={{ required: "Select atleast one hierarchy" }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Box
                  sx={
                    error
                      ? { border: 1, borderRadius: 1, borderColor: "red" }
                      : {}
                  }
                  border={error ? 1 : 0}
                  borderRadius={error ? 1 : 0}
                >
                  <ProductHierarchyConfig
                    selectedHierarchy={value || []}
                    setSelectedHierarchy={onChange}
                  />
                </Box>
              )}
            />
          </Box>
        </Card>
      ) : (
        <CaseConfigLoader />
      )}
    </Paper>
  );
}
