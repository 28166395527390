import React, { FC } from "react";
import Box from "@mui/material/Box";
import { Card, Skeleton, IconButton } from "@mui/material";
import styles from "./CaseConfig.module.scss";

const CaseConfigLoader: FC = () => {
  return (
    <Card className={styles.caseCard}>
      {[1, 2, 3].map((_, index) => (
        <Box className={styles.caseConditionBox} key={index}>
          <Skeleton width="30%" height={40} className={styles.daysField} />
          <Box className={styles.caseConditionSingleBox}>
            <Skeleton width={20} height={30} className={styles.conditionName} />
            <Skeleton width="50%" height={40} className={styles.daysField} />
          </Box>

          <Box className={styles.Button} sx={{ display: "flex", gap: 2 }}>
            <IconButton>
              <Skeleton variant="circular" width={30} height={30} />
            </IconButton>
            <IconButton>
              <Skeleton variant="circular" width={30} height={30} />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Card>
  );
};

export default CaseConfigLoader;
