import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { NavigationLinkProvider } from "./contexts/NavigationLink";
import routes from "./routes";
import { useEffect } from "react";

const router = createBrowserRouter(routes);
const RINGER_AUDIO = new Audio(`${window.origin}/ringer.wav`);

function App() {
  useEffect(() => {
    const playUnmutedRinger = () => {
      RINGER_AUDIO.muted = false;
      RINGER_AUDIO.play()
        .then((_) => console.log("Ringer played successfully"))
        .catch((err) => console.log("ERROR PLAYING RINGER", err));
    };
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        console.log("Recived Ringer Event!!!");
        if (event.data && event.data.type === "PLAY_AUDIO") {
          playUnmutedRinger();
        }
      });
    }

    const mutedRingerPlay = () => {
      RINGER_AUDIO.muted = true;
      RINGER_AUDIO.play()
        .then((_) => console.log("Muted audio play successfull!"))
        .catch((err) => console.log("Muted audio play failed!", err));
    };

    document.addEventListener("click", mutedRingerPlay, { once: true });
    return () => {
      document.removeEventListener("click", mutedRingerPlay);
    };
  }, []);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <NavigationLinkProvider>
        <RouterProvider router={router} />
      </NavigationLinkProvider>
    </LocalizationProvider>
  );
}

export default App;
