import axios from "axios";
import { MemberReportConfigType } from "../interfaces";
import config from "../../../config";
const { apiServerUrl } = config;
const reportType = "member_report";
export const upsertMemberReportConfig = async (
  businessUnitId: number,
  data: MemberReportConfigType
) => {
  const updateRequest = {
    configType: reportType,
    config: data,
  };
  return await axios
    .post(
      `${apiServerUrl}/users/business-configs/${businessUnitId}/${reportType}`,
      updateRequest
    )
    .then((res) => res.data.count);
};
export const getMemberReportConfig = async (
  businessUnitId: number
): Promise<MemberReportConfigType | null> => {
  return await axios
    .get(
      `${apiServerUrl}/users/business-configs/${businessUnitId}/${reportType}`
    )
    .then((res) => res.data);
};
