import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const CreateOffer = lazy(() => import("../components/CreateOffer"));

const OfferConfigRoute: RouteObject = {
  path: "/offers/create",
  element: <CreateOffer />,
  children: [
    {
      path: "view/:code",
      element: <CreateOffer />,
    },
    {
      path: 'clone/:code',
      element: <CreateOffer />,
    },
  ],
};

export default OfferConfigRoute;
