import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const OfferList = lazy(() => import("../components/OfferList"));

const OfferListConfigRoute: RouteObject = {
  path: "/offers",
  element: <OfferList />,
};

export default OfferListConfigRoute;
