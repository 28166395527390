import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';
import React, { FC } from 'react';
import styles from './LoadingButton.module.scss';
import { classnames } from '../../../utils';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}

const LoadingButton: FC<LoadingButtonProps> = ({
  loading,
  children,
  disabled,
  ...rest
}) => {
  return (
    <Button {...rest} disabled={loading || disabled} id={styles.loadingButton}>
      <Box component='span' sx={{ opacity: loading ? 0 : 1 }}>
        {children}
      </Box>
      {loading && (
        <Box
          className={classnames(styles.loaderContainer, {
            [styles.loaderContainerWithIcon]: !!rest.startIcon,
          })}
        >
          <CircularProgress size={16} color='inherit' />
        </Box>
      )}
    </Button>
  );
};

export default LoadingButton;
