export enum MemberReportTabsEnum {
  Recency = "recency",
  Frequency = "frequency",
  Monetary = "monetary",
  Hierarchy = "hierarchy",
  AnalysisPeriod = "analysisPeriod",
}
export const memberReportConfigTabs = [
  {
    value: MemberReportTabsEnum.Recency,
    label: "Recency",
  },
  {
    value: MemberReportTabsEnum.Frequency,
    label: "Visits",
  },
  {
    value: MemberReportTabsEnum.Monetary,
    label: "Monetary",
  },
  {
    value: MemberReportTabsEnum.Hierarchy,
    label: "Hierarchy",
  },
  {
    value: MemberReportTabsEnum.AnalysisPeriod,
    label: "Analysis Period",
  },
];
