import { Box, Typography } from '@mui/material';
import { classnames } from '../../../../../../utils';
import styles from './HierarchyCard.module.scss';
import useUser from '../../../../../../hooks/useUser';

interface HierarchyCardProps {
  value: string;
  onChange: (hierarchy: string) => void;
  selected: boolean;
  disabled?: boolean;
}

const HierarchyCard = ({
  value,
  selected,
  onChange,
  disabled,
}: HierarchyCardProps) => {
  const { selectedBusiness } = useUser();
  const productHierarchy = selectedBusiness?.productHierarchy;
  const label = productHierarchy.find(
    (hierarchy) => hierarchy.value === value
  )?.label;

  return (
    <Box
      className={classnames(
        styles.card,
        { [styles.selectedCard]: selected },
        { [styles.disabledCard]: !!disabled }
      )}
      onClick={() => {
        if (!disabled) onChange(value);
      }}
    >
      <Typography align="center">{label}</Typography>
    </Box>
  );
};

export default HierarchyCard;
