import axios from 'axios';

import config from '../config';
import User, { UserTag, UserTagFilter } from '../interfaces/User';
import { identity, pickBy } from 'lodash';

const { apiServerUrl, ruleServerUrl } = config;

export const getUser = () => {
  return axios.get<User>(`${apiServerUrl}/users`).then(({ data }) => data);
};

export const getPosToken = (id: string) => {
  return axios
    .get(`${ruleServerUrl}/users/get-token/${id}`)
    .then(({ data }) => data);
};

export const getUserTags = (
  filters: UserTagFilter,
  abortController?: AbortController
): Promise<UserTag[]> => {
  return axios
    .get<UserTag[]>(`${apiServerUrl}/users/tags`, {
      params: pickBy(filters, identity),
      signal: abortController?.signal,
    })
    .then((response) => response.data);
};
