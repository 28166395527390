import { Box } from '@mui/material';
import HierarchyCard from '../HierarchyCard/HierarchyCard';
import styles from './HierarchySelect.module.scss';

interface HierarchySelectProps {
  options: string[];
  value: string[];
  onChange: (value: string[]) => void;
  disableSelection: boolean;
}

const HierarchySelect = ({
  options,
  value,
  onChange,
  disableSelection,
}: HierarchySelectProps) => {
  const handleChange = (hierarchy: string) => {
    // deselect
    if (value.includes(hierarchy)) {
      if (value.length === 1) return;
      onChange(value.filter((item) => item !== hierarchy));
    }
    // select
    else onChange([...value, hierarchy]);
  };

  return (
    <Box className={styles.hierarchyCardContainer}>
      {
        // show selected hierarchies on top
        value.map((val) => (
          <HierarchyCard
            disabled={
              (value.length === 1 && value.includes(val)) || disableSelection
            }
            value={val}
            selected={value.includes(val)}
            onChange={handleChange}
          />
        ))
      }
      {
        // filtering out unselected hierarchies
        options
          .filter((option) => !value.includes(option))
          .map((option) => (
            <HierarchyCard
              disabled={disableSelection}
              value={option}
              selected={value.includes(option)}
              onChange={handleChange}
            />
          ))
      }
    </Box>
  );
};

export default HierarchySelect;
