import { Box, SxProps, Theme, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { FC } from 'react';

interface HeaderProps {
  title: string;
  description?: string;
  sx?: SxProps<Theme>;
  variant?: Variant;
}

const Header: FC<HeaderProps> = ({
  title,
  description,
  variant = 'h5',
  sx = {},
}) => {
  return (
    <Box component='header' sx={{ mb: 2, ...sx }}>
      <Typography variant={variant}>{title}</Typography>
      {description && (
        <Typography variant='body2' color='GrayText' sx={{ mt: 0.25 }}>
          {description}
        </Typography>
      )}
    </Box>
  );
};
export default Header;
